import React from 'react';
import { Link } from 'react-router-dom';
import SecondaryButton from '../SecondaryButton';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';
import PremiumPill from '../PremiumPill';

const UsersTable = ({ data, loading, limit, page }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-center  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          S.No
        </th>
        <th className="px-6 py-3 text-center  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Subscription
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Name/Email
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Phone Number
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Last Activity
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Diet Plan
        </th>
        <th scope="col" className="px-6 py-3">
          <span className="sr-only">Details</span>
        </th>
      </TableHeader>
      <tbody>
        {loading || !data ? (
          <tr className="w-full">
            <td>LOADING</td>

            {/* <Skeleton height={50} count={10} /> */}
          </tr>
        ) : (
          data.map((td, i) => (
            <TableRow key={td['_id']} i={i}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {limit * (page - 1) + 1 + i}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                <PremiumPill value={td.premium} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {td.name || td.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {td.phone_number || 'NA'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {new Date(td.last_activity).toLocaleString()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {td.active_diet_plan}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <Link
                  to={`${td['_id']}`}
                  className="text-indigo-600 text-xs font-semibold hover:text-indigo-900"
                >
                  <SecondaryButton title={'View Profile'} />
                </Link>
              </td>
            </TableRow>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default UsersTable;
