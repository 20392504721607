import React from 'react';

const BodyTitle = ({ text }) => {
  return (
    <div className="text-xl leading-6 font-semibold text-gray-700 mt-6">
      {text}
    </div>
  );
};

export default BodyTitle;
