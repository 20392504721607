import React from 'react';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';

const SelectSnacksTable = ({ data, loading, limit, page, handleAddSnack }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-center  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          S.No
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Name
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Description
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Image
        </th>
        <th scope="col" className="px-6 py-3">
          <span className="sr-only">Select</span>
        </th>
      </TableHeader>
      <tbody>
        {loading ? (
          <div className="w-full">
            LOADING
            {/* <Skeleton height={50} count={10} /> */}
          </div>
        ) : (
          data.map((td, i) => (
            <TableRow key={td['_id']} i={i}>
              <td className="px-6 py-4 text-sm font-medium text-gray-900 text-center">
                {limit * (page - 1) + 1 + i}
              </td>
              <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
                {td.name}
              </td>
              <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
                {td.description}
              </td>
              <td className="px-6 py-4 text-sm font-medium text-gray-900">
                <img
                  className="mx-auto h-12 w-auto rounded-lg"
                  src={td.image}
                  alt="Snack"
                />
              </td>
              <td className="px-6 py-4 w-16 text-sm font-medium text-gray-900 text-center">
                <button
                  className="text-red-600 text-xs font-semibold hover:text-red-900"
                  onClick={() => {
                    handleAddSnack(td);
                  }}
                >
                  SELECT
                </button>
              </td>
            </TableRow>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default SelectSnacksTable;
