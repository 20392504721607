import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SnacksList from './list';
import NewSnack from './new';
import ViewSnack from './view';

const Snacks = () => {
  return (
    <>
      <Routes>
        <Route index element={<SnacksList />} />
        <Route path="new" element={<NewSnack />} />
        <Route path=":id" element={<ViewSnack />} />
      </Routes>
    </>
  );
};

export default Snacks;
