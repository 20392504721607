import React from 'react';
import SnacksTable from '../../../components/SnacksTable';
import HeaderTitle from '../../../components/HeaderTitle';

import Pagination from '../../../components/Table/Pagination';
import Input from '../../../components/Form/Input';
import useSnacks from '../../../hooks/Snack/useSnacks';
import PrimaryButton from '../../../components/PrimaryButton';
import { Link } from 'react-router-dom';

const SnacksList = () => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const { data: response, loading } = useSnacks({
    page,
    limit,
    search: searchQuery,
  });

  return (
    <div className="flex flex-col space-y-4">
      <HeaderTitle title="Snacks">
        <Link to={'new'}>
          <PrimaryButton title={'+ New Snack Item'} />
        </Link>
      </HeaderTitle>
      <div className="flex space-x-2 py-4">
        <div className="w-full"></div>
        <div className="w-8/12">
          <Input
            type="text"
            placeholder="Search Snacks"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      {response ? (
        <>
          <SnacksTable
            data={response?.data}
            loading={loading}
            limit={limit}
            page={page}
          />
          <Pagination
            page={page}
            setPage={setPage}
            currPage={response?.page}
            totalPages={response?.total_pages}
            totalResults={response?.total_results}
            currLength={response?.data.length}
            tableLimit={limit}
            setTableLimit={setLimit}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SnacksList;
