import React from 'react';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';

const RecipeIngredientsTable = ({ component, data }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          S No.
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Quantity
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Item
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Description (Optional)
        </th>
      </TableHeader>
      <tbody>
        {data.map((td, i) => (
          <TableRow key={i} i={i}>
            <td className="w-24 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
              {i + 1}
            </td>
            <td className="w-24 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
              {`${td.quantity} - ${td.ingredient.unit}`}
            </td>
            <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
              {td.ingredient.name}
            </td>
            <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
              {td.description}
            </td>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default RecipeIngredientsTable;
