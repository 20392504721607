import React from 'react';

const Pagination = ({
  page,
  setPage,
  currPage,
  totalPages,
  totalResults,
  currLength,
  tableLimit,
  setTableLimit,
}) => {
  const [limit, setLimit] = React.useState(tableLimit);
  const handleTableLimit = () => {
    setTableLimit(limit);
  };

  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border rounded-md shadow-sm border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:flex space-x-2 items-center">
        <p className="text-sm text-gray-700">
          Showing{' '}
          <span className="font-medium">
            {totalResults > 0 ? tableLimit * (page - 1) + 1 : 0}
          </span>{' '}
          to{' '}
          <span className="font-medium">
            {tableLimit * (page - 1) + currLength}
          </span>{' '}
          of <span className="font-medium">{totalResults}</span> results
        </p>
        <div className="text-sm text-gray-700 flex items-center space-x-2">
          <span> - </span>
          <span>Limit</span>
          <input
            className="w-12 font-bold"
            type="number"
            onChange={(e) => setLimit(e.target.value)}
            value={limit}
          />
          <button
            type="button"
            onClick={handleTableLimit}
            className="inline-flex items-center px-2 py-1 border border-gray-300 hover:border-gray-500 transition-colors shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Change
          </button>
        </div>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <button
          onClick={() => setPage((p) => p - 1)}
          disabled={currPage === 1}
          className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() => setPage((p) => p + 1)}
          disabled={currPage === totalPages}
          className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
