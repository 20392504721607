import React from 'react';
import BackButton from '../BackButton';

const FormLayout = ({
  children,
  title,
  desc,
  onSubmit,
  wide,
  backButton = true,
}) => {
  return (
    <div className={`md:w-3/4 ${wide ? '' : 'lg:w-1/2'}`}>
      {title && (
        <div>
          {backButton ? <BackButton /> : <></>}
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            {title}
          </h3>
          {desc && <p className="mt-1 text-sm text-gray-500">{desc}</p>}
        </div>
      )}
      <form onSubmit={onSubmit} className="space-y-6 divide-y divide-gray-200">
        <div className="my-6 space-y-6">{children}</div>
      </form>
    </div>
  );
};

export default FormLayout;
