import React from 'react';

const Input = ({
  id,
  name,
  label,
  type,
  placeholder,
  register,
  noPadd,
  ...inputProps
}) => {
  return (
    <div className="flex flex-col space-y-2">
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="">
        <input
          type={type}
          name={name}
          id={id}
          className={`border border-gray-300 shadow-sm pl-3 ${
            noPadd ? 'pr-2' : 'pr-10'
          } py-2 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md`}
          placeholder={placeholder}
          {...register}
          {...inputProps}
        />
      </div>
    </div>
  );
};

export default Input;
