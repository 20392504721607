import axios from 'axios';
import React from 'react';
import { API_URL } from '../configs';
import { useAuth } from './auth';

const axiosContext = React.createContext();

export const AxiosProvider = ({ children }) => {
  const { user } = useAuth();

  const authAxios = axios.create({
    baseURL: API_URL,
  });

  const getHeader = async () => {
    const token = await user.getIdToken();
    return 'Bearer ' + token;
  };

  authAxios.interceptors.request.use(
    async (config) => {
      config.headers.Authorization = await getHeader();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <axiosContext.Provider value={authAxios}>{children}</axiosContext.Provider>
  );
};

export const useAxios = () => {
  return React.useContext(axiosContext);
};
