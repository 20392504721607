import React from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormLayout from '../../../components/Form/FormLayout';
import FormSection from '../../../components/Form/FormSection';
import Input from '../../../components/Form/Input';
import ImageInput from '../../../components/Form/ImageInput';
import DropDown from '../../../components/DropDown';
import IngredientsDropDown from '../../../components/IngredientsDropDown';
import { useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../../../contexts/axios';
import { toast } from 'react-toastify';
import { diet_plans, meal_categories } from '../../../configs/data';
import BodyTitle from '../../../components/BodyTitle';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import { TrashIcon } from '@heroicons/react/solid';

const CleanButton = ({ title, onClick, ...inputProps }) => {
  return (
    <button
      type="button"
      className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-200 font-medium text-blue-800 hover:bg-blue-300 text-sm"
      onClick={onClick}
      {...inputProps}
    >
      {title}
    </button>
  );
};

const IngredientsItems = ({ index, control, register }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `ingredients[${index}].items`,
  });

  return (
    <div className="space-y-4 ml-4">
      {fields.map((item, k) => {
        return (
          <div key={item.id} className="space-y-4">
            <Controller
              name={`ingredients[${index}].items[${k}].ingredient`}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <IngredientsDropDown
                  onChange={onChange}
                  value={value}
                  label={`Ingredient ${k + 1}`}
                  defaultText={'Select an Ingredient'}
                  displayProperty={'name'}
                  idProperty={'_id'}
                ></IngredientsDropDown>
              )}
            />
            <FormSection>
              <Input
                id={`ingredients[${index}].items[${k}].quantity`}
                register={register(
                  `ingredients[${index}].items[${k}].quantity`
                )}
                label={'Quantity'}
                type="number"
                placeholder={1}
                noPadd
              />
              <Input
                label="Description"
                placeholder="2 slices (toasted in 1 tsp of butter or ghee)"
                id={`ingredients[${index}].items[${k}].description`}
                register={register(
                  `ingredients[${index}].items[${k}].description`
                )}
                type="text"
                noPadd
              />
              <TrashIcon
                className="text-red-600 h-6 w-6 mt-auto mb-2"
                onClick={() => remove(k)}
              />
            </FormSection>
          </div>
        );
      })}

      <CleanButton title={'Add Ingredient'} onClick={() => append({})} />
      <hr />
    </div>
  );
};

const InstructionsItems = ({ index, control, register }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `instructions[${index}].items`,
  });

  return (
    <div className="space-y-4 ml-4">
      {fields.map((item, k) => {
        return (
          <div key={item.id} className="space-y-4">
            <FormSection className="w-full">
              <h1 className="my-auto">{`${k + 1}`}</h1>
              <Input
                placeholder="Instruction"
                id={`instructions[${index}].items[${k}]`}
                type="text"
                defaultValue={item.f1}
                register={register(`instructions[${index}].items[${k}]`, {
                  required: true,
                })}
                noPadd
              />
              <TrashIcon
                className="text-red-600 h-6 w-6 mt-auto mb-2"
                onClick={() => remove(k)}
              />
            </FormSection>
          </div>
        );
      })}

      <CleanButton title={'Add Instruction'} onClick={() => append('')} />
      <hr />
    </div>
  );
};

const IngredientsComponent = ({ control, register, setValue, getValues }) => {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'ingredients',
  });
  return (
    <div>
      <div className="text-md font-semibold text-gray-700 mb-4">
        Ingredients
      </div>
      <div>
        {fields.map((item, index) => {
          return (
            <div key={item.id} className="mb-4 space-y-4">
              <FormSection>
                <Input
                  placeholder="Component Name"
                  id={`ingredients[${index}].component`}
                  register={register(`ingredients[${index}].component`)}
                  type="text"
                  noPadd
                />
                <TrashIcon
                  className="text-red-600 h-6 w-6 mt-auto mb-2"
                  onClick={() => remove(index)}
                />
              </FormSection>
              <IngredientsItems index={index} {...{ control, register }} />
            </div>
          );
        })}
      </div>
      <div className="">
        <CleanButton
          title={'New Component'}
          onClick={() => {
            append({ component: '' });
          }}
        />
      </div>
    </div>
  );
};

const InstructionsComponent = ({ control, register, setValue, getValues }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'instructions',
  });
  return (
    <div>
      <div className="text-md font-semibold text-gray-700 mb-4">
        Instructions
      </div>
      <div>
        {fields.map((item, index) => {
          return (
            <div key={item.id} className="mb-4 space-y-4">
              <FormSection>
                <Input
                  placeholder="Component Name"
                  id={`instructions[${index}].component`}
                  type="text"
                  register={register(`instructions[${index}].component`)}
                  noPadd
                />
                <TrashIcon
                  className="text-red-600 h-6 w-6 mt-auto mb-2"
                  onClick={() => remove(index)}
                />
              </FormSection>
              <InstructionsItems index={index} {...{ control, register }} />
            </div>
          );
        })}
      </div>
      <div className="">
        <CleanButton
          title={'New Component'}
          onClick={() => {
            append({ component: '' });
          }}
        />
      </div>
    </div>
  );
};

const NewRecipe = () => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    reset,
    setValue,
    watch,
  } = useForm();

  const authAxios = useAxios();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const watchRecipeImage = watch('image');
  const [uploadProgress, setUploadProgress] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (percentCompleted <= 99) {
        setUploadProgress(percentCompleted);
      }
    },
  };

  const uploadImageMutation = useMutation(
    (formData) => authAxios.post('/recipes/image', formData, config),
    {
      onMutate: () => {
        setUploadProgress(0);
      },
      onSuccess: (response) => {
        setUploadProgress(100);
        toast.success('Successfully uploaded image.');
        const { data } = response;
        const { publicUrl } = data;
        setImageUrl(publicUrl);
        alert(`Image is uploaded at: ${imageUrl}.`);
      },
      onError: (error) => {
        console.log(error);
        toast.error('Error uploading image.');
      },
      onSettled: () => {
        setUploadProgress(null);
      },
    }
  );

  const onSubmit = async (data) => {
    setUploadProgress(0);
    const formData = new FormData();
    formData.append('image', data.image[0]);
    await uploadImageMutation.mutateAsync(formData);
    data['image'] = imageUrl;
    await createRecipe.mutateAsync(data);
  };

  const createRecipe = useMutation(
    (payload) => authAxios.post(`/recipes`, payload),
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(['recipes']);
        toast.success('New Recipe Created');
        navigate(`/recipes`, { replace: true });
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  return (
    <div>
      <FormLayout
        title={'Create New Recipe'}
        onSubmit={handleSubmit(onSubmit)}
        wide
      >
        <div className="space-y-4">
          <Input
            label="Name"
            placeholder="Whole Wheat Bread"
            id="name"
            name="name"
            type="text"
            noPadd
            register={register('name', {
              required: 'This field is required.',
            })}
          />
          <Input
            label="Description"
            placeholder="2 slices (toasted in 1 tsp of butter or ghee)"
            id="description"
            name="description"
            type="text"
            register={register('description', {
              required: 'This field is required.',
            })}
          />
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <DropDown
                onChange={onChange}
                value={value}
                label={'Type'}
                options={['Veg', 'Non-Veg']}
              ></DropDown>
            )}
          />
          <Controller
            name="diet"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <DropDown
                onChange={onChange}
                value={value}
                label={'Diet'}
                defaultText={'Select a Diet'}
                options={diet_plans}
              ></DropDown>
            )}
          />
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <DropDown
                onChange={onChange}
                value={value}
                label={'Category'}
                defaultText={'Select a Category'}
                options={meal_categories}
              ></DropDown>
            )}
          />
        </div>
        <FormSection>
          <Input
            label="Servings"
            id="servings"
            type="number"
            step="1"
            placeholder={1}
            noPadd={true}
            defaultValue={1}
            register={register('servings.quantity', {
              required: 'This field is required.',
            })}
          />
          <Input
            label="Unit"
            id="servings_unit"
            placeholder={'Persons (Optional)'}
            noPadd={true}
            register={register('servings.unit')}
          />
        </FormSection>
        <FormSection>
          <Input
            label="Cook Time (Lower)"
            id="cook_time_lower"
            type="number"
            step="1"
            placeholder={10}
            noPadd={true}
            register={register('time.cook.lower', {
              required: 'This field is required.',
            })}
          />
          <Input
            label="Cook Time (Upper)"
            id="cook_time_upper"
            type="number"
            step="1"
            placeholder={20}
            noPadd={true}
            register={register('time.cook.upper', {
              required: 'This field is required.',
            })}
          />
        </FormSection>
        <FormSection>
          <Input
            label="Prep Time (Lower)"
            id="prep_time_lower"
            type="number"
            step="1"
            placeholder={10}
            noPadd={true}
            register={register('time.prep.lower', {
              required: 'This field is required.',
            })}
          />
          <Input
            label="Prep Time (Upper)"
            id="prep_time_upper"
            type="number"
            step="1"
            placeholder={20}
            noPadd={true}
            register={register('time.prep.upper', {
              required: 'This field is required.',
            })}
          />
        </FormSection>
        <IngredientsComponent
          {...{ control, register, getValues, setValue, errors }}
        />

        <InstructionsComponent
          {...{ control, register, getValues, setValue, errors }}
        />
        <FormSection>
          <ImageInput
            label="Recipe Image"
            fileList={watchRecipeImage}
            register={register('image', {
              required: 'Please upload the recipe image.',
            })}
            errors={errors}
          />
        </FormSection>
        <button
          type="submit"
          className="disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {uploadProgress ? `${uploadProgress} %` : 'Create'}
        </button>
      </FormLayout>
    </div>
  );
};

export default NewRecipe;
