import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackButton from '../../../../components/BackButton';
import DropDown from '../../../../components/DropDown';
import HeaderTitle from '../../../../components/HeaderTitle';
import useSnacks from '../../../../hooks/Snack/useSnacks';
import Pagination from '../../../../components/Table/Pagination';
import SelectSnacksTable from '../../../../components/SnacksTable/select';
import ObjectJSONView from '../../../../components/Developer/ObjectJSONView';
import { useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../../../../contexts/axios';
import useDebounce from '../../../../hooks/useDebounce';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { diet_plans, snack_categories } from '../../../../configs/data';
import Input from '../../../../components/Form/Input';


const AddSnackView = () => {
  const { state } = useLocation();
  const [category, setCategory] = React.useState('');
  const [calories, setCalories] = React.useState({ min: 0, max: 1500 });
  const [searchQuery, setSearchQuery] = React.useState('');
  const debouncedCalories = useDebounce(calories, 500);
  const [diet, setDiet] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const authAxios = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: response, loading} = useSnacks({
    page,
    limit,
    // calories_gte: debouncedCalories.min,
    // calories_lte: debouncedCalories.max,
    search: searchQuery,
  });

  const { mealplan, plan, index } = state;

  const addSnackMutation = useMutation(
    (payload) => authAxios.post(`mealplans/add/snack`, payload),
    {
      onSuccess: (response) => {
        console.log(response);
        queryClient.invalidateQueries(['mealplan', mealplan._id]);
        toast.success('Added Snack');
        navigate(`/mealplans/${mealplan._id}`, { state: {index: index}, replace: true });
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  const handleAddSnack = async (snack) => {
    if (category == '') {
        alert("please select a category")
        return
    }
    await addSnackMutation.mutateAsync({
      plan_id: plan._id,
      snack_id: snack._id,
      snack_category: category,
    });
  };

//   React.useEffect(() => {
//     setCategory(meal.category);
//     setDiet(mealplan.diet);
//     const currentCalories = meal.recipe.nutrition.calories;
//     setCalories({
//       min: (currentCalories - 200) | 0,
//       max: (currentCalories + 200) | 0,
//     });
//   }, [mealplan, meal]);

  return (
    <div className="flex flex-col space-y-4">
        <BackButton />
        <HeaderTitle title={'Add Snack'} />
      
      
      <div className="flex-1 md:flex-row flex-col flex items-center space-y-2 md:space-y-0 md:space-x-4 justify-end">
        <div className="w-4/12">
          <Input
            type="text"
            placeholder="Search Snack"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        </div>
        <div className="flex-1 md:flex-row flex-col flex items-center space-y-2 md:space-y-0 md:space-x-4 justify-end">

        
        <div className="flex-1 w-full">
          <DropDown
            label={'Snack Category'}
            value={category}
            options={snack_categories}
            onChange={(value) => setCategory(value)}
          ></DropDown>
        </div>
        {/* <div className="flex-1 w-full space-y-4 mx-4 pr-4">
          <p className="text-sm font-medium text-gray-700">Calories</p>
          <InputRange
            minValue={0}
            maxValue={1500}
            value={calories}
            onChange={(value) => setCalories(value)}
          />
        </div>
        
        
        <div className="flex-1 w-full">
          <DropDown
            label={'Diet Plan'}
            value={diet}
            options={diet_plans}
            onChange={(value) => setDiet(value)}
          ></DropDown>
        </div> */}
      </div>
      {response ? (
        <>
          <SelectSnacksTable
            data={response?.data}
            loading={loading}
            limit={limit}
            page={page}
            handleAddSnack={handleAddSnack}
          />
          <Pagination
            page={page}
            setPage={setPage}
            currPage={response?.page}
            totalPages={response?.total_pages}
            totalResults={response?.total_results}
            currLength={response?.data.length}
            tableLimit={limit}
            setTableLimit={setLimit}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddSnackView;
