import React from 'react';

import HeaderTitle from '../../../components/HeaderTitle';
import UsersTable from '../../../components/UsersTable';
import Pagination from '../../../components/Table/Pagination';
import Input from '../../../components/Form/Input';
import useUsers from '../../../hooks/User/useUsers';
import DropDown from '../../../components/DropDown';
import { user_types } from '../../../configs/data';

const UsersList = () => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [type, setType] = React.useState('All');

  const { data: response, loading } = useUsers({
    page,
    limit,
    search: searchQuery,
    type: type,
  });

  return (
    <div className="flex flex-col space-y-4">
      <HeaderTitle title="Users" />
      <div className="flex space-x-2 py-4">
        <div className="flex-1"></div>
        <div className="flex-1 w-full">
          <DropDown
            label={'User Type'}
            value={type}
            options={user_types}
            onChange={(value) => setType(value)}
          ></DropDown>
        </div>
        <div className="w-6/12">
          <Input
            label={'Search'}
            type="text"
            placeholder="Search Users"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      {response ? (
        <>
          <UsersTable
            data={response?.data}
            loading={loading}
            limit={limit}
            page={page}
          />
          <Pagination
            page={page}
            setPage={setPage}
            currPage={response?.page}
            totalPages={response?.total_pages}
            totalResults={response?.total_results}
            currLength={response?.data.length}
            tableLimit={limit}
            setTableLimit={setLimit}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UsersList;
