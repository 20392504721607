import React from 'react';

const Information = ({ title, value }) => {
  return (
    <div className="my-4">
      <div className="text-md leading-6 font-semibold text-gray-500">
        {title}
      </div>
      <div className="text-2xl leading-6 font-semibold text-gray-900">
        {value}
      </div>
    </div>
  );
};

export default Information;
