import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuth } from './contexts/auth';

import Dashboard from './pages/dashboard';
import Auth from './pages/auth';
import LoadingSpinner from './components/LoadingSpinner';

import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-schedule/styles/material.css';
import './App.css';

function App() {
  const { loading, user } = useAuth();

  const ProtectedRoutes = () => {
    return user ? <Outlet /> : <Navigate to="/auth" />;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/*" element={<Dashboard />} />
        </Route>
        <Route path="/auth" element={<Auth />} />
      </Routes>
    </>
  );
}

export default App;
