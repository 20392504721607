import React from 'react';

import Table from '../../../../components/Table/Table';
import TableHeader from '../../../../components/Table/TableHeader';
import TableRow from '../../../../components/Table/TableRow';
import PremiumPill from '../../../../components/PremiumPill';

const UserProfile = ({ user }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Email
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Diet Plan
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Last Activity
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Joined
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none"></th>
      </TableHeader>
      <tbody>
        <TableRow>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.email}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.active_diet_plan}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {new Date(user?.last_activity).toLocaleString()}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {new Date(user?.createdAt).toLocaleString()}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            <PremiumPill value={user?.premium} /> {user?.offer_identifier}
          </td>
        </TableRow>
      </tbody>
    </Table>
  );
};

export default UserProfile;
