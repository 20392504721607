import React from 'react';
import { registerLicense } from '@syncfusion/ej2-base';
import {
  ScheduleComponent,
  Day,
  Week,
  Inject,
  ViewsDirective,
  ViewDirective,
} from '@syncfusion/ej2-react-schedule';
import useCalendar from '../../../hooks/Calendar/useCalendar';
import { SYNCFUSION_LICENSE_KEY } from '../../../configs';
import { useAxios } from '../../../contexts/axios';
import { useAuth } from '../../../contexts/auth';

registerLicense(SYNCFUSION_LICENSE_KEY);

const Schedule = () => {
  const [scheduleData, setScheduleData] = React.useState([]);

  const authAxios = useAxios();
  const { user } = useAuth();

  const [view, setView] = React.useState('Week');
  const [date, setDate] = React.useState(new Date());

  const { data: response } = useCalendar({
    view,
    date,
    nutritionist: user.admin ? null : user.oid,
  });

  React.useEffect(() => {
    if (!!response) {
      setScheduleData(
        response.data.map((event) => {
          return {
            Id: event.id,
            IsReadonly: event.read_only,
            Description: event.description || '',
            Subject: event.subject + ' - ' + event.nutritionist.name,
            StartTime: event.start_time,
            EndTime: event.end_time,
            Location: event.location || null,
          };
        })
      );
    }
  }, [response]);

  const updateRemoteEvent = async (payload) => {
    await authAxios.post('calendar/event/', payload);
  };

  const onNavigating = (data) => {
    if (!!data.currentView) {
      setView(data.currentView);
    }
    if (!!data.currentDate) {
      setDate(data.currentDate);
    }
  };

  const onPopUp = (data) => {
    if (!!data.data.Location) {
      document.querySelector(
        '.e-location-details'
      ).innerHTML = `<a href="${data.data.Location}" target="_blank">Join Google Meet Link</>`;
    }
  };

  const onActionComplete = (data) => {
    var event = null;
    switch (data.requestType) {
      case 'eventCreated':
        data.addedRecords[0].Subject = 'Available';
        event = data.addedRecords[0];
        updateRemoteEvent({
          id: event.Id,
          action: 'create',
          subject: event.Subject,
          start_time: event.StartTime,
          end_time: event.EndTime,
          nutritionist: user.oid,
        });
        break;
      case 'eventRemoved':
        event = data.deletedRecords[0];
        updateRemoteEvent({
          id: event.Id,
          action: 'delete',
          nutritionist: user.oid,
        });
      default:
        break;
    }
  };

  const eventTemplate = React.useCallback((props) => {
    if (props.IsReadonly) {
      return (
        <div className="bg-orange-600 font-bold text-md p-2">
          {props.Subject}
        </div>
      );
    } else {
      return (
        <div className="bg-indigo-400 font-bold text-md p-2">
          {props.Subject}
        </div>
      );
    }
  }, []);

  return (
    <div>
      <ScheduleComponent
        popupOpen={onPopUp}
        navigating={onNavigating}
        actionComplete={onActionComplete}
        currentView={view}
        eventSettings={{
          dataSource: scheduleData,
          template: eventTemplate,
          fields: {
            subject: {
              title: 'Event Name',
              name: 'Subject',
              default: 'Available',
            },
          },
        }}
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
        </ViewsDirective>
        <Inject services={[Day, Week]} />
      </ScheduleComponent>
    </div>
  );
};

export default Schedule;
