import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';
import { capitalize } from '../../utils/string.utils';
import { TrashIcon } from '@heroicons/react/outline';
import RecipeInstructionModal from '../../pages/dashboard/mealplans/Modal/RecipeInstructionModal';

const MealPlansTable = ({ mealplan, plan, handleDeleteRecipe }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 w-16 text-center  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          S.No
        </th>
        <th className="px-6 py-3 w-32 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Category
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Name
        </th>
        <th className="px-6 py-3 w-16 text-center  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Consumed
        </th>
        <th scope="col" className="w-16 px-6 py-3">
          <span className="sr-only">Manage</span>
        </th>
        <th scope="col" className="w-16 px-6 py-3">
          <span className="sr-only">Manage</span>
        </th>
      </TableHeader>
      <tbody>
        {plan?.meals.map((meal, i) => (
          <TableRow key={meal['_id']} i={i}>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
              {i + 1}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
              {capitalize(meal.category)}
            </td>
            <td className="px-6 py-4 text-md font-medium text-gray-900 text-left">
              {meal.recipe ? (
                <Link
                  to={`/recipes/${meal.recipe._id}`}
                  className="text-indigo-600 font-semibold hover:text-indigo-900"
                >
                  {meal.recipe.name}
                </Link>
              ) : (
                <Link
                  to={`/snacks/${meal.snack?._id}`}
                  className="text-indigo-600 font-semibold hover:text-indigo-900"
                >
                  {meal.snack?.name}
                </Link>
              )}
              <br />
              <RecipeInstructionModal plan_id={mealplan._id} meal={meal} />
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
              {meal.consumed ? 'Yes' : 'No'}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              {meal.recipe ? (
                <Link
                  to={`change`}
                  state={{ mealplan, meal }}
                  className="text-blue-600 font-semibold hover:text-blue-900"
                >
                  Change
                </Link>
              ) : (
                <></>
              )}
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-right text-sm font-medium">
              <TrashIcon
                className="text-red-500 h-4 w-4"
                onClick={() => {
                  handleDeleteRecipe(mealplan._id, meal._id);
                }}
              />
            </td>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default MealPlansTable;
