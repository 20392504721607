import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Users from './users';
import Recipes from './recipes';
import Ingredients from './ingredients';
import Analytics from './analytics';
import Settings from './settings';
import Snacks from './snacks';
import MealPlans from './mealplans';
import Schedule from './schedule';

const Dashboard = () => {
  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Sidebar />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 px-8">
            <Routes>
              <Route index element={<Navigate to={'recipes'} />} />
              <Route path="recipes/*" element={<Recipes />} />
              <Route path="ingredients/*" element={<Ingredients />} />
              <Route path="snacks/*" element={<Snacks />} />
              <Route path="users/*" element={<Users />} />
              <Route path="mealplans/*" element={<MealPlans />} />
              <Route path="analytics/*" element={<Analytics />} />
              <Route path="settings/*" element={<Settings />} />
              <Route path="schedule/*" element={<Schedule />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
