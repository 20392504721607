import React from 'react';

import Table from '../../../../components/Table/Table';
import TableHeader from '../../../../components/Table/TableHeader';
import TableRow from '../../../../components/Table/TableRow';

const HealthProfile = ({ user }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Age
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Gender
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Weight
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Height
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Calories Goal
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Water Goal
        </th>
      </TableHeader>
      <tbody>
        <TableRow>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.health_profile?.age || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.health_profile?.gender || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.health_profile?.weight || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.health_profile?.height || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.meal_profile?.calories_goal || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.meal_profile?.water_goal || 'N/A'}
          </td>
        </TableRow>
      </tbody>
    </Table>
  );
};

export default HealthProfile;
