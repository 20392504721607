import React from 'react';
import BasicModal from '../Modals/BasicModal';

const ModalButton = ({ title, children, open, setOpen }) => {
  return (
    <div>
      <button
        type="button"
        className="w-48 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
        onClick={() => {
          setOpen(true);
        }}
      >
        {title ? title : ''}
      </button>
      <BasicModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        {children}
      </BasicModal>
    </div>
  );
};

export default ModalButton;
