import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchUsers = async (authAxios, { page, limit, search, type }) => {
  const params = {
    page: page,
    limit: limit ? limit : 10,
    search: search,
    type: type,
  };
  try {
    const { data } = await authAxios.get(`/users`, { params });
    return data;
  } catch (e) {
    console.error(e);
  }
};

const useUsers = ({ page, limit, search, type }) => {
  const authAxios = useAxios();
  return useQuery(['users', page, limit, search, type], () =>
    fetchUsers(authAxios, { page, limit, search, type })
  );
};

export default useUsers;
