import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchNutritionists = async (authAxios) => {
  try {
    const params = {};
    const { data } = await authAxios.get(`/nutritionists`, { params });
    return data?.data;
  } catch (e) {
    console.error(e);
  }
};

const useNutritionists = () => {
  const authAxios = useAxios();
  return useQuery(['nutritionists'], () => fetchNutritionists(authAxios));
};

export default useNutritionists;
