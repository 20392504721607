import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';
import PrimaryButton from '../PrimaryButton';
import moment from 'moment';

const UpcomingMealPlansTable = ({ data, loading, limit, page }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          S.No
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Name/Email
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Diet
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          ETA
        </th>
        <th scope="col" className="px-6 py-3">
          <span className="sr-only">Details</span>
        </th>
      </TableHeader>
      <tbody>
        {loading ? (
          <div className="w-full">LOADING</div>
        ) : (
          data.map((td, i) => (
            <TableRow key={td['_id']} i={i}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {limit * (page - 1) + 1 + i}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {td.user?.name || td.user?.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {td.diet}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {td.start_date ? moment(td.start_date).fromNow() : ''}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <Link to={`/mealplans/${td['_id']}`}>
                  <PrimaryButton title={'Customize Meal Plan'} />
                </Link>
              </td>
            </TableRow>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default UpcomingMealPlansTable;
