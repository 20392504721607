import React from 'react';
import useFirebase from '../hooks/useFirebase';

const authContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const auth = useFirebase();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(authContext);
};
