import React from 'react';
import { useParams } from 'react-router-dom';
import HeaderTitle from '../../../components/HeaderTitle';
import BackButton from '../../../components/BackButton';
import useSnack from '../../../hooks/Snack/useSnack';
import NutritionCard from '../../../components/NutritionCard';

const ViewSnack = () => {
  const { id } = useParams();
  const { data: snack, loading } = useSnack({ id });
  return (
    <>
      <BackButton />
      <HeaderTitle title={snack?.name} subtitle={snack?.description} />
      <NutritionCard nutrition={snack?.nutrition} />
    </>
  );
};

export default ViewSnack;
