import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchRecipes = async (
  authAxios,
  { page, limit, search, category, diet, calories_gte, calories_lte }
) => {
  const params = {
    page: page,
    limit: limit ? limit : 10,
    search: search,
    category: category,
    diet: diet,
    calories_gte: calories_gte ? calories_gte : 0,
    calories_lte: calories_lte ? calories_lte : 5000,
  };
  try {
    const { data } = await authAxios.get(`/recipes`, { params });
    return data;
  } catch (e) {
    console.error(e);
  }
};

const useRecipes = ({
  page,
  limit,
  search,
  category,
  diet,
  calories_gte,
  calories_lte,
}) => {
  const authAxios = useAxios();
  return useQuery(
    [
      'recipes',
      page,
      limit,
      search,
      category,
      diet,
      calories_gte,
      calories_lte,
    ],
    () =>
      fetchRecipes(authAxios, {
        page,
        limit,
        search,
        category,
        diet,
        calories_gte,
        calories_lte,
      })
  );
};

export default useRecipes;
