import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchSnack = async (authAxios, { id }) => {
  const params = {
    _id: id,
  };
  try {
    const { data } = await authAxios.get(`/snacks`, { params });
    return data?.data?.[0];
  } catch (e) {
    console.error(e);
  }
};

const useSnack = ({ id }) => {
  const authAxios = useAxios();
  return useQuery(['snack', id], () => fetchSnack(authAxios, { id }));
};

export default useSnack;
