import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchIngredients = async (authAxios, { page, limit, search }) => {
  const params = {
    page: page,
    limit: limit ? limit : 10,
    search: search,
  };
  try {
    const { data } = await authAxios.get(`/ingredients`, { params });
    return data;
  } catch (e) {
    console.error(e);
  }
};

const useIngredients = ({ page, limit, search }) => {
  const authAxios = useAxios();
  return useQuery(['ingredients', page, limit, search], () =>
    fetchIngredients(authAxios, { page, limit, search })
  );
};

export default useIngredients;
