const HeaderTitle = ({ title, subtitle, children }) => {
  return (
    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div>
        <h3 className="text-2xl leading-6 font-semibold text-gray-900">
          {title}
        </h3>
        {subtitle ? (
          <h3 className="text-md leading-6 font-medium text-gray-500 mt-3">
            {subtitle}
          </h3>
        ) : (
          <></>
        )}
      </div>
      <div className="mt-2 sm:mt-0 sm:ml-4 flex space-x-2">{children}</div>
    </div>
  );
};

export default HeaderTitle;
