import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PremiumPill = ({ value }) => {
  return (
    <span
      className={classNames(
        'px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm',
        value ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'
      )}
    >
      {value ? 'Premium' : 'Free'}
    </span>
  );
};

export default PremiumPill;
