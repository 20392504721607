import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useParams, Link, useLocation } from 'react-router-dom';
import useMealPlan from '../../../hooks/MealPlans/useMealPlan';
import HeaderTitle from '../../../components/HeaderTitle';
import BackButton from '../../../components/BackButton';
import Information from '../../../components/Information';
import MealPlansTable from '../../../components/MealPlansTable';
import PrimaryButton from '../../../components/PrimaryButton';
import AddSnackModal from './Modal/AddSnackModal';
import { useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../../../contexts/axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth';

const DateTitle = ({ date }) => {
  const dateString = new Date(date)
    .toUTCString()
    .split(' ')
    .slice(0, 4)
    .join(' ');

  return (
    <div className="text-xl leading-6 font-semibold text-gray-700 my-4">
      {dateString}
    </div>
  );
};

const MealPlanView = () => {
  const { state } = useLocation();
  const { user } = useAuth();
  const { id } = useParams();
  const authAxios = useAxios();
  const queryClient = useQueryClient();
  const { data: response } = useMealPlan({
    id: id,
  });

  useEffect(() => {
    if (!!state) {
      const { index } = state;
      if(!!index) {
        const element = document.getElementById(`day-${index}`);
        if (!!element) {
          element.scrollIntoView({behavior: 'smooth'});
        }
      }
    }
  }, [state]);

  const publishMealPlanMutation = useMutation(
    (payload) => authAxios.post(`mealplans/publish`, payload),
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(['mealplan', response._id]);
        toast.success('Published MealPlan');
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  const handlePublishMealPlan = async (mealplan_id) => {
    await publishMealPlanMutation.mutateAsync({
      mealplan_id: mealplan_id,
      nutritionist_id: user.oid 
    });
  };

  const deleteRecipeMutation = useMutation(
    (payload) => authAxios.post(`mealplans/delete/recipe`, payload),
    {
      onSuccess: (response) => {
        console.log(response);
        queryClient.invalidateQueries(['mealplan', id]);
        toast.success('Deleted Recipe');
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  const handleDeleteRecipe = async (plan_id, meal_id) => {
    await deleteRecipeMutation.mutateAsync({
      plan_id: plan_id,
      meal_id: meal_id,
    });
  };

  if (!response) {
    return <></>;
  }

  return (
    <>
      <BackButton />
      <HeaderTitle title={`Meal Plan`}></HeaderTitle>
      <div>
        <div className="flex items-center">
          <div>
            <Information
              title={'Diet Plan'}
              value={response.user?.active_diet_plan}
            />
            <Information
              title={'Calories Goal'}
              value={`${response.user?.meal_profile?.calories_goal} calories`}
            />
            <Information
              title={'Water Goal'}
              value={`${response.user?.meal_profile?.water_goal} glasses`}
            />
          </div>
          <div className="ml-auto">
            {response.published ? (
              <></>
            ) : (
              <PrimaryButton
                title={'Publish'}
                onClick={() => handlePublishMealPlan(response._id)}
              />
            )}
          </div>
        </div>

        {response?.plans.map((plan, i) => (
          <div key={i} className="py-4">
            <div className="flex items-center">
              <div id={`day-${i}`} className="text-xl leading-6 font-bold text-gray-700 my-4">
                Day {i + 1}
              </div>
              <div className="ml-auto">
                <Link
                  to={`add-snack`}
                  state={{ mealplan: response, plan: plan, index: i }}
                  className="text-blue-600 font-semibold hover:text-blue-900"
                >
                  <PrimaryButton
                    title={'+ Add Snack'}
                  />
                </Link>
              </div>
            </div>
            <MealPlansTable
              key={i}
              mealplan={response}
              plan={plan}
              handleDeleteRecipe={handleDeleteRecipe}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default MealPlanView;
