import React from 'react';
import { useParams } from 'react-router-dom';
import HeaderTitle from '../../../components/HeaderTitle';
import BackButton from '../../../components/BackButton';
import useUser from '../../../hooks/User/useUser';
import useNutritionists from '../../../hooks/User/useNutritionists';
import UserProfile from './Tables/UserProfile';
import BodyTitle from '../../../components/BodyTitle';
import HealthProfile from './Tables/HealthProfile';
import MealProfile from './Tables/MealProfile';
import { useAxios } from '../../../contexts/axios';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import PrimaryButton from '../../../components/PrimaryButton';
import ModalButton from '../../../components/Modals/ModalButton';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { registerLicense } from '@syncfusion/ej2-base';
import { SYNCFUSION_LICENSE_KEY } from '../../../configs';
import { useAuth } from '../../../contexts/auth';
import DropDown from '../../../components/DropDown';

registerLicense(SYNCFUSION_LICENSE_KEY);

const ScheduleCallView = ({ user, setOpen }) => {
  const [date, setDate] = React.useState(null);
  const [nutritionist, setNutritionist] = React.useState(null);
  const { user: dashboard_user } = useAuth();
  const { data: nutritionists, loading } = useNutritionists();
  const authAxios = useAxios();

  var filtered_nutritionists = nutritionists?.filter((n) => {
    if (dashboard_user.admin) {
      return true;
    }
    if (n._id === dashboard_user.oid) {
      return true;
    }
    return false;
  });

  React.useEffect(() => {
    if (
      !!!nutritionist &&
      !!filtered_nutritionists &&
      filtered_nutritionists.length != 0
    ) {
      setNutritionist(filtered_nutritionists[0]);
    }
  }, [filtered_nutritionists]);

  const scheduleCallMutation = useMutation(
    (payload) => authAxios.post(`calendar/schedule-call`, payload),
    {
      onSuccess: (response) => {
        console.log(response);
        toast.success('Call scheduled with user.');
        setOpen(false);
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  const bookCallClicked = async () => {
    if (!date) {
      alert('Please select a date and time.');
      return;
    }
    await scheduleCallMutation.mutateAsync({
      user_id: user._id,
      nutritionist_id: nutritionist._id,
      event_time: date,
    });
  };

  return (
    <div className="space-y-4">
      <div className="text-lg font-bold">
        Schedula a call with {user?.name} ({user?.email})
      </div>
      <div className="w-5/12">
        <DropDown
          label={'Select Nutritionist'}
          value={nutritionist}
          onChange={setNutritionist}
          options={filtered_nutritionists}
          displayProperty={'name'}
        ></DropDown>
      </div>
      <div className="w-5/12">
        <div>
          <DateTimePickerComponent
            placeholder="Select a date and time"
            min={new Date()}
            onChange={({ value }) => {
              setDate(value);
            }}
          />
        </div>
      </div>
      <div>
        <PrimaryButton title={'Book Call'} onClick={bookCallClicked} />
      </div>
    </div>
  );
};

const ScheduleCallModal = ({ user }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <ModalButton title={'Schedule Call'} open={open} setOpen={setOpen}>
      <ScheduleCallView user={user} setOpen={setOpen} />
    </ModalButton>
  );
};

const UserView = () => {
  const { id } = useParams();
  const { data: user, loading } = useUser({ id });
  const [notes, setNotes] = React.useState('');
  const authAxios = useAxios();

  React.useEffect(() => {
    if (user) {
      setNotes(user.notes);
    }
  }, [user]);

  const updateNotesMutation = useMutation(
    (payload) => authAxios.post(`users/`, payload),
    {
      onSuccess: (response) => {
        console.log(response);
        toast.success('Updated User Notes');
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  const handleSaveNotes = async () => {
    await updateNotesMutation.mutateAsync({
      email: user.email,
      notes: notes,
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <BackButton />
      <HeaderTitle title={`Profile - ${user?.name || user?.email || user?.phone_number}`}>
        <ScheduleCallModal user={user} />
      </HeaderTitle>
      <BodyTitle text={''} />
      <UserProfile user={user} />
      <div className="py-2"></div>
      <BodyTitle text={'Health Profile'} />
      <HealthProfile user={user} />
      <div className="py-2"></div>
      <BodyTitle text={'Meal Profile'} />
      <MealProfile user={user} />
      <div className="py-4"></div>
      <BodyTitle text={'User Notes'} />
      <form onSubmit={() => {}}>
        <label>
          <textarea
            className="p-4 w-6/12 rounded-lg shadow-sm"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </label>
      </form>
      <PrimaryButton title={'Save Notes'} onClick={handleSaveNotes} />
    </div>
  );
};

export default UserView;
