import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import TextInput from '../components/Form/Input';
import { useAuth } from '../contexts/auth';

const Auth = () => {
  const { user, logIn } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = async (credentials) => {
    console.log(credentials);
    try {
      await logIn(credentials.email, credentials.password);
    } catch (e) {
      console.log(e);
    }
  };

  if (!!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-24 w-auto rounded-lg"
          src="/images/mealguide-logo.png"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          MealGuide Dashboard
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(handleLogin)}>
            <TextInput
              id="email"
              label="Email"
              type="email"
              name="email"
              register={{
                ...register('email', {
                  required: 'This field is required.',
                }),
              }}
              errors={errors.email}
            />

            <TextInput
              id="password"
              label="Password"
              type="password"
              name="password"
              register={{
                ...register('password', {
                  required: 'This field is required.',
                }),
              }}
              error={errors.password}
            />
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Auth;
