import React from 'react';
import { auth } from '../utils/firebase';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

const useFirebase = () => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const sanitizeUser = async (rawUser) => {
    if (rawUser) {
      const user = await formatUser(rawUser);
      setUser(user);
      setLoading(false);
      return user;
    } else {
      setUser(false);
      setLoading(false);
      return false;
    }
  };

  const logIn = async (email, password) => {
    setLoading(true);
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      const sanitizedUser = sanitizeUser(response.user);
      return sanitizedUser;
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    return null;
  };

  const logOut = async () => {
    setLoading(true);
    try {
      signOut(auth);
      return sanitizeUser(false);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    return null;
  };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, sanitizeUser);
    return () => unsubscribe();
  }, []);

  return {
    user,
    loading,
    logIn,
    logOut,
  };
};

const formatUser = async (user) => {
  try {
    const { claims } = await user.getIdTokenResult();
    user['admin'] = claims?.admin || false;
    user['oid'] = claims?.oid || null;
  } catch (error) {}
  return user;
};

export default useFirebase;
