import React from 'react';

const FormSection = ({ children }) => {
  return (
    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div className="sm:col-span-6 flex space-x-4">{children}</div>
    </div>
  );
};

export default FormSection;
