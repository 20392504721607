import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';
import { capitalize } from '../../utils/string.utils';

const IngredientsTable = ({ data, loading, limit, page }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-center  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          S.No
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Name
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Category
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Quantity - Weight
        </th>
        <th scope="col" className="px-6 py-3">
          <span className="sr-only">Details</span>
        </th>
      </TableHeader>
      <tbody>
        {loading ? (
          <div className="w-full">
            LOADING
            {/* <Skeleton height={50} count={10} /> */}
          </div>
        ) : (
          data.map((td, i) => (
            <TableRow key={td['_id']} i={i}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {limit * (page - 1) + 1 + i}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {td.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {capitalize(td.category)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {`${td.quantity} - ${td.unit} - ${td.serving_weight}`}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <Link
                  to={`${td['_id']}`}
                  className="text-indigo-600 text-xs font-semibold hover:text-indigo-900"
                >
                  More Details
                </Link>
              </td>
            </TableRow>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default IngredientsTable;
