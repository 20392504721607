import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchMealPlan = async (authAxios, { page, limit, published, user }) => {
  const params = {
    page: page,
    limit: limit,
    published: published,
    user: user,
  };
  try {
    const { data } = await authAxios.get(`/mealplans`, { params });
    return data;
  } catch (e) {
    console.error(e);
  }
};

const useMealPlans = ({ page, limit, published, user }) => {
  const authAxios = useAxios();
  return useQuery(['mealplans', page, limit, published, user], () =>
    fetchMealPlan(authAxios, { page, limit, published, user })
  );
};

export default useMealPlans;
