const snack_categories = [
  'Wake Up',
  'Pre Breakfast',
  'Breakfast',
  'Post Breakfast',
  'Pre Workout (Morning)',
  'Post Workout (Morning)',
  'Mid Morning Snack',
  'Pre Lunch',
  'Lunch',
  'Post Lunch',
  'Mid Afternoon Snack',
  'Early Evening Snack',
  'Evening Snack',
  'Late Evening Snack',
  'Pre Workout (Evening)',
  'Post Workout (Evening)',
  'Dinner',
  'Post Dinner',
  'Bed Time',
];

const diet_plans = [
  'Gluten Free',
  'Keto',
  'High Protein',
  'Vegetarian',
  'Vegan',
  'PCOD',
  'Diabetes Friendly',
  'Paleo',
  'Balanced Diet',
  'Low Carb',
  'DASH',
  'Mediterranean',
  'Intermittent Fasting',
  'Others',
].sort();

const meal_categories = ['breakfast', 'lunch', 'snacks', 'dinner'];

const user_types = ['All', 'Free', 'Premium', 'Promo'];

export { snack_categories, diet_plans, meal_categories, user_types };
