import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import {
  CogIcon,
  TemplateIcon,
  PresentationChartBarIcon,
  UsersIcon,
  ColorSwatchIcon,
  CubeIcon,
  CalendarIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/outline';
import useCurrentPath from '../hooks/useCurrentPath';
import { useAuth } from '../contexts/auth';

const navigation = [
  {
    name: 'Recipes',
    href: '/recipes',
    icon: TemplateIcon,
    adminOnly: false,
  },
  {
    name: 'Ingredients',
    href: '/ingredients',
    icon: ColorSwatchIcon,
    adminOnly: false,
  },
  {
    name: 'Snacks',
    href: '/snacks',
    icon: CubeIcon,
    adminOnly: false,
  },
  {
    name: 'Users',
    href: '/users',
    icon: UsersIcon,
    adminOnly: false,
  },
  {
    name: 'Meal Plans',
    href: '/mealplans',
    icon: ClipboardCopyIcon,
    children: [
      { name: 'Upcoming Meal Plans', href: '/mealplans/upcoming' },
      { name: 'Active Meal Plan', href: '/mealplans/active' },
    ],
    adminOnly: false,
  },
  {
    name: 'Schedule',
    href: '/schedule',
    icon: CalendarIcon,
    adminOnly: false,
  },
  {
    name: 'Analytics',
    href: '/analytics',
    icon: PresentationChartBarIcon,
    children: [
      { name: 'Subscriptions', href: '/analytics/subscriptions' },
      { name: 'Events', href: '/analytics/events' },
      { name: 'Users', href: '/analytics/users' },
    ],
    adminOnly: true,
  },
  {
    name: 'Settings',
    href: '/settings',
    icon: CogIcon,
    adminOnly: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const isCurrentPath = (path, value) => {
  return path === value;
};

const Sidebar = () => {
  //   const { signOut } = useAuth();

  const { user, logOut } = useAuth();

  const currentPath = useCurrentPath();

  return (
    <>
      {/* Static sidebar for desktop */}
      {/* hidden */}
      <div className="md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center justify-center px-4">
                <img
                  className="h-14 w-auto rounded-lg"
                  src="/images/mealguide-logo.png"
                  alt="MealGuide"
                />
                <p className="pl-4 text-lg leading-6 font-semibold text-gray-500">
                  Dashboard
                </p>
              </div>
              <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                {navigation.map((item) =>
                  item.adminOnly && !user.admin ? (
                    <></>
                  ) : !item.children ? (
                    <div key={item.name}>
                      <Link
                        to={`${item.href}`}
                        className={classNames(
                          isCurrentPath(currentPath, item.href)
                            ? 'bg-gray-100 text-gray-900'
                            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            isCurrentPath(currentPath, item.href)
                              ? 'text-indigo-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </div>
                  ) : (
                    <Disclosure as="div" key={item.name} className="space-y-1">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              isCurrentPath(currentPath, item.href)
                                ? 'bg-gray-100 text-gray-900'
                                : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                isCurrentPath(currentPath, item.href)
                                  ? 'text-indigo-500'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            <span className="flex-1">{item.name}</span>
                            <svg
                              className={classNames(
                                open
                                  ? 'text-gray-400 rotate-90'
                                  : 'text-gray-300',
                                'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                              )}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path
                                d="M6 6L14 10L6 14V6Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {item.children.map((subItem) => (
                              <Link
                                key={subItem.name}
                                to={`${subItem.href}`}
                                className={`${
                                  isCurrentPath(currentPath, subItem.href)
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                }group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md `}
                              >
                                {subItem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                )}
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <button
                onClick={logOut}
                className="w-full bg-gray-200 p-4 text-gray-600 rounded-md hover:bg-gray-300 transition-colors"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
