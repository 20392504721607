import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchEvents = async (authAxios, { view, date, nutritionist }) => {
  const params = {
    view: view,
    date: date,
    nutritionist: nutritionist,
  };
  try {
    const { data } = await authAxios.get(`/calendar`, { params });
    return data;
  } catch (e) {
    console.error(e);
  }
};

const useCalendar = ({ view, date, nutritionist }) => {
  const authAxios = useAxios();
  return useQuery(['calendar', view, date, nutritionist], () =>
    fetchEvents(authAxios, { view, date, nutritionist })
  );
};

export default useCalendar;
