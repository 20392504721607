import React from 'react';
import { Link } from 'react-router-dom';
import SecondaryButton from '../SecondaryButton';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';
import moment from 'moment';

const ColouredTableRow = ({ children, endDate }) => {
  console.log(moment().diff(endDate, 'days'));

  const backgroundColor = () => {
    const diff = moment().diff(endDate, 'days');
    if (diff >= -4) {
      return 'bg-red-400';
    }
    if (diff >= -10) {
      return 'bg-yellow-400';
    }
    return 'bg-green-400';
  };
  return <tr className={`${backgroundColor()}`}>{children}</tr>;
};

const ActiveMealPlansTable = ({ data, loading, limit, page }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          S.No
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Name/Email
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Diet
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Start Date
        </th>
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          End Date
        </th>
        <th scope="col" className="px-6 py-3">
          <span className="sr-only">Details</span>
        </th>
      </TableHeader>
      <tbody>
        {loading ? (
          <div className="w-full">LOADING</div>
        ) : (
          data.map((td, i) => (
            <ColouredTableRow key={td['_id']} endDate={moment(td.end_date)}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {limit * (page - 1) + 1 + i}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {td.user.name || td.user.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {td.diet}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {td.start_date ? moment(td.start_date).fromNow() : ''}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {td.end_date ? moment(td.end_date).fromNow() : ''}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <Link to={`/mealplans/${td['_id']}`}>
                  <SecondaryButton title={'View Meal Plan'} />
                </Link>
              </td>
            </ColouredTableRow>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default ActiveMealPlansTable;
