import React from 'react';

import Table from '../../../../components/Table/Table';
import TableHeader from '../../../../components/Table/TableHeader';
import TableRow from '../../../../components/Table/TableRow';

const HealthProfile = ({ user }) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Condition
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Food Preference
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Increased Intake
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Lifestyle
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Weight Goal
        </th>
      </TableHeader>
      <tbody>
        <TableRow>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.meal_profile?.condition || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.meal_profile?.food_preference || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.meal_profile?.increased_intake || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.meal_profile?.lifestyle || 'N/A'}
          </td>
          <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {user?.meal_profile?.weight_goal || 'N/A'}
          </td>
        </TableRow>
      </tbody>
    </Table>
  );
};

export default HealthProfile;
