import React from 'react';
import { useParams } from 'react-router-dom';
import HeaderTitle from '../../../components/HeaderTitle';
import useRecipe from '../../../hooks/Recipe/useRecipe';
import BodyTitle from '../../../components/BodyTitle';
import BackButton from '../../../components/BackButton';
import RecipeInstructionsTable from '../../../components/RecipesTable/instructions';
import RecipeIngredientsTable from '../../../components/RecipesTable/ingredients';
import ObjectJSONView from '../../../components/Developer/ObjectJSONView';
import NutritionCard from '../../../components/NutritionCard';

const ComponentTitle = ({ component }) => {
  return component && component !== '' ? (
    <div className="mb-2 ml-2 text-md font-semibold text-gray-500">
      {component}
    </div>
  ) : (
    <></>
  );
};

const ViewRecipe = () => {
  const { id } = useParams();
  const { data: recipe, loading } = useRecipe({ id });
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <BackButton />
      <HeaderTitle title={recipe?.name} subtitle={recipe?.description} />
      <NutritionCard nutrition={recipe?.nutrition} />
      <BodyTitle text={'Ingredients'} />
      {recipe?.ingredients?.map((ingredient, i) => (
        <div key={i} className="my-4">
          <ComponentTitle component={ingredient.component} />
          <RecipeIngredientsTable
            component={ingredient.component}
            data={ingredient.items}
          />
        </div>
      ))}
      <BodyTitle text={'Instructions'} />
      {recipe?.instructions?.map((instruction, i) => (
        <div key={i} className="my-4">
          <ComponentTitle component={instruction.component} />
          <RecipeInstructionsTable
            component={instruction.component}
            data={instruction.items}
          />
        </div>
      ))}
      {/* <ObjectJSONView object={recipe} /> */}
    </>
  );
};

export default ViewRecipe;
