import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchSnacks = async (
  authAxios,
  { page, limit, search, category, diet }
) => {
  const params = {
    page: page,
    limit: limit ? limit : 10,
    search: search,
    category: category,
    diet: diet,
  };
  try {
    const { data } = await authAxios.get(`/snacks`, { params });
    return data;
  } catch (e) {
    console.error(e);
  }
};

const useSnacks = ({ page, limit, search, category }) => {
  const authAxios = useAxios();
  return useQuery(['snacks', page, limit, search, category], () =>
    fetchSnacks(authAxios, { page, limit, search, category })
  );
};

export default useSnacks;
