import React from 'react';
import useMealPlans from '../../../../hooks/MealPlans/useMealPlans';
import HeaderTitle from '../../../../components/HeaderTitle';
import ActiveMealPlansTable from '../../../../components/MealPlansTable/active';
import Pagination from '../../../../components/Table/Pagination';

const ActiveMealPlans = () => {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const { data: response, loading } = useMealPlans({
    page,
    limit,
    published: true,
  });

  return (
    <div className="flex flex-col space-y-4">
      <HeaderTitle title="Active Meal Plans" />
      {response ? (
        <>
          <ActiveMealPlansTable
            data={response?.data}
            loading={loading}
            limit={limit}
            page={page}
          />
          <Pagination
            page={page}
            setPage={setPage}
            currPage={response?.page}
            totalPages={response?.total_pages}
            totalResults={response?.total_results}
            currLength={response?.data.length}
            tableLimit={limit}
            setTableLimit={setLimit}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ActiveMealPlans;
