import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';
import { capitalize } from '../../utils/string.utils';

const SelectRecipesTable = ({
  data,
  loading,
  limit,
  page,
  handleUpdateRecipe,
}) => {
  return (
    <Table>
      <TableHeader>
        <th className="px-6 py-3 text-center  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          S.No
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Name
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Calories
        </th>
        <th className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Diet
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
          Category
        </th>
        <th scope="col" className="px-6 py-3">
          <span className="sr-only">Select</span>
        </th>
      </TableHeader>
      <tbody>
        {loading ? (
          <div className="w-full">
            LOADING
            {/* <Skeleton height={50} count={10} /> */}
          </div>
        ) : (
          data.map((td, i) => (
            <TableRow key={td['_id']} i={i}>
              <td className="px-6 py-4 w-16 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                {limit * (page - 1) + 1 + i}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                <Link
                  to={`/recipes/${td['_id']}`}
                  className="text-indigo-600 text-xs font-semibold hover:text-indigo-900"
                >
                  {td.name}
                </Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {td.nutrition.calories}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {td.diet}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                {capitalize(td.category)}
              </td>
              <td className="px-6 py-4 w-16 text-sm font-medium text-gray-900 text-center">
                <button
                  className="text-red-600 text-xs font-semibold hover:text-red-900"
                  onClick={() => {
                    handleUpdateRecipe(td);
                  }}
                >
                  SELECT
                </button>
              </td>
            </TableRow>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default SelectRecipesTable;
