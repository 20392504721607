import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MealPlanView from './view';
import ChangeRecipeView from './edit';
import UpcomingMealPlans from './upcoming';
import ActiveMealPlans from './active';
import AddSnackView from './edit/AddSnack';

const MealPlans = () => {
  return (
    <>
      <Routes>
        <Route index element={<Navigate to={'/users'} />} />
        <Route path="upcoming/*" element={<UpcomingMealPlans />} />
        <Route path="active/*" element={<ActiveMealPlans />} />
        <Route path=":id/*" element={<MealPlanView />} />
        <Route path=":id/change" element={<ChangeRecipeView />} />
        <Route path=":id/add-snack" element={<AddSnackView />} />
      </Routes>
    </>
  );
};

export default MealPlans;
