import React from 'react';
import IngredientsTable from '../../../components/IngredientsTable';
import HeaderTitle from '../../../components/HeaderTitle';

import Pagination from '../../../components/Table/Pagination';
import Input from '../../../components/Form/Input';
import useIngredients from '../../../hooks/Ingredient/useIngredients';

const IngredientsList = () => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);

  const { data: response, loading } = useIngredients({
    page,
    limit,
    search: searchQuery,
  });

  return (
    <div className="flex flex-col space-y-4">
      <HeaderTitle title="Ingredients" />
      <div className="flex space-x-2 py-4">
        <div className="w-full"></div>
        <div className="w-8/12">
          <Input
            type="text"
            placeholder="Search Ingredients"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      {response ? (
        <>
          <IngredientsTable
            data={response?.data}
            loading={loading}
            limit={limit}
            page={page}
          />
          <Pagination
            page={page}
            setPage={setPage}
            currPage={response?.page}
            totalPages={response?.total_pages}
            totalResults={response?.total_results}
            currLength={response?.data.length}
            tableLimit={limit}
            setTableLimit={setLimit}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default IngredientsList;
