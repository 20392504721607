import React from 'react';

const SecondaryButton = ({ title, onClick }) => {
  return (
    <button
      type="button"
      className="w-32 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm"
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default SecondaryButton;
