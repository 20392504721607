import React from 'react';
import { Routes, Route } from 'react-router-dom';

import UsersList from './list';
import UserView from './view';

const Users = () => {
  return (
    <>
      <Routes>
        <Route index element={<UsersList />} />
        <Route path="mealplan/:id" element={<h1>View User's Meal Plan</h1>} />
        <Route path=":id" element={<UserView />} />
      </Routes>
    </>
  );
};

export default Users;
