import React from 'react';
import BasicModal from '../../../../components/Modals/BasicModal';
import { useForm } from 'react-hook-form';
import FormLayout from '../../../../components/Form/FormLayout';
import Input from '../../../../components/Form/Input';
import { useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../../../../contexts/axios';
import { toast } from 'react-toastify';
import PrimaryButton from '../../../../components/PrimaryButton';

export const RecipeInstructionView = ({ plan_id, meal, setOpen }) => {
  const { handleSubmit, register } = useForm({
    defaultValues: {
      instruction: meal?.instruction,
    },
  });
  const authAxios = useAxios();
  const queryClient = useQueryClient();

  const updateInstruction = useMutation(
    (payload) => authAxios.post(`/mealplans/update/instruction`, payload),
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(['mealplan', plan_id]);
        toast.success('Instruction Updated');
        setOpen(false);
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  const onSubmit = async (data) => {
    await updateInstruction.mutateAsync({
      instruction: data['instruction'],
      plan_id,
      meal_id: meal._id,
    });
  };

  return (
    <div className="pt-4">
      <FormLayout
        title={'Update Instruction for Meal'}
        onSubmit={handleSubmit(onSubmit)}
        wide={true}
        backButton={false}
      >
        <Input
          placeholder="2 slices (toasted in 1 tsp of butter or ghee)"
          id="instruction"
          name="instruction"
          type="text"
          register={register('instruction')}
          noPadd={true}
        />
        <PrimaryButton title="Save" type="submit"></PrimaryButton>
      </FormLayout>
    </div>
  );
};

const RecipeInstructionModal = ({ plan_id, meal }) => {
  const [open, setOpen] = React.useState(false);
  const { instruction } = meal;
  return (
    <div>
      <button onClick={() => setOpen(true)}>
        {instruction ? (
          <div className="text-sm font-semibold text-black-500">
            {instruction}
          </div>
        ) : (
          <div className="text-sm font-light text-black-500">
            Add Instruction
          </div>
        )}
      </button>
      <BasicModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <RecipeInstructionView
          plan_id={plan_id}
          meal={meal}
          setOpen={setOpen}
        />
      </BasicModal>
    </div>
  );
};

export default RecipeInstructionModal;
