import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackButton from '../../../../components/BackButton';
import DropDown from '../../../../components/DropDown';
import HeaderTitle from '../../../../components/HeaderTitle';
import useRecipes from '../../../../hooks/Recipe/useRecipes';
import Pagination from '../../../../components/Table/Pagination';
import SelectRecipesTable from '../../../../components/RecipesTable/select';
import ObjectJSONView from '../../../../components/Developer/ObjectJSONView';
import { useMutation } from 'react-query';
import { useAxios } from '../../../../contexts/axios';
import useDebounce from '../../../../hooks/useDebounce';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { diet_plans, meal_categories } from '../../../../configs/data';
import Input from '../../../../components/Form/Input';

const ChangeRecipeView = () => {
  const { state } = useLocation();
  const [category, setCategory] = React.useState('');
  const [calories, setCalories] = React.useState({ min: 0, max: 1500 });
  const [searchQuery, setSearchQuery] = React.useState('');
  const debouncedCalories = useDebounce(calories, 500);
  const [diet, setDiet] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(50);
  const authAxios = useAxios();
  const navigate = useNavigate();

  const changeRecipeMutation = useMutation(
    (payload) => authAxios.post(`mealplans/update/recipe`, payload),
    {
      onSuccess: (response) => {
        console.log(response);
        // queryClient.setQueryData(['module', data['_id']], editedData.data.data);
        toast.success('Updated Recipe');
        navigate(`/mealplans/${mealplan._id}`, { replace: true });
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  const { data: response, loading } = useRecipes({
    page,
    limit,
    category: category.toLowerCase(),
    diet,
    calories_gte: debouncedCalories.min,
    calories_lte: debouncedCalories.max,
    search: searchQuery,
  });

  const { mealplan, meal } = state;

  const handleUpdateRecipe = async (recipe) => {
    const caloriesDiff = Math.abs(
      meal.recipe.nutrition.calories - recipe.nutrition.calories || 0
    );

    if (caloriesDiff > 200) {
      toast.error(
        `Selecting this recipe will alter the user's mealplan by more than ${caloriesDiff} calories.`
      );
    }
    await changeRecipeMutation.mutateAsync({
      plan_id: mealplan._id,
      meal_id: meal._id,
      recipe_id: recipe._id,
    });
  };

  console.log(mealplan, meal);

  React.useEffect(() => {
    setCategory(meal.category);
    setDiet(mealplan.diet);
    const currentCalories = meal.recipe.nutrition.calories;
    setCalories({
      min: (currentCalories - 200) | 0,
      max: (currentCalories + 200) | 0,
    });
  }, [mealplan, meal]);

  return (
    <div className="flex flex-col space-y-4">
      <BackButton />
      <HeaderTitle title={'Change Recipe'} />
      <div className="flex-1 md:flex-row flex-col flex items-center space-y-2 md:space-y-0 md:space-x-4 justify-end">
        <div className="flex-1 w-full">
          <Input
            type="text"
            placeholder="Search Recipes"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="flex-1 w-full space-y-4 mx-4 pr-4">
          <p className="text-sm font-medium text-gray-700">Calories</p>
          <InputRange
            minValue={0}
            maxValue={1500}
            value={calories}
            onChange={(value) => setCalories(value)}
          />
        </div>
        <div className="flex-1 w-full">
          <DropDown
            label={'Meal Category'}
            value={category}
            options={meal_categories}
            onChange={(value) => setCategory(value)}
          ></DropDown>
        </div>
        <div className="flex-1 w-full">
          <DropDown
            label={'Diet Plan'}
            value={diet}
            options={diet_plans}
            onChange={(value) => setDiet(value)}
          ></DropDown>
        </div>
      </div>
      {response ? (
        <>
          <SelectRecipesTable
            data={response?.data}
            loading={loading}
            limit={limit}
            page={page}
            handleUpdateRecipe={handleUpdateRecipe}
          />
          <Pagination
            page={page}
            setPage={setPage}
            currPage={response?.page}
            totalPages={response?.total_pages}
            totalResults={response?.total_results}
            currLength={response?.data.length}
            tableLimit={limit}
            setTableLimit={setLimit}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChangeRecipeView;
