// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'nutrify-meal-guide.firebaseapp.com',
  databaseURL: 'https://nutrify-meal-guide.firebaseio.com',
  projectId: 'nutrify-meal-guide',
  storageBucket: 'nutrify-meal-guide.appspot.com',
  messagingSenderId: '424839644258',
  appId: '1:424839644258:web:c32b5d297bac3f82733d3b',
  measurementId: 'G-Q472CN3RRH',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, analytics, auth };
