import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchUser = async (authAxios, { id }) => {
  const params = {
    _id: id,
  };
  try {
    const { data } = await authAxios.get(`/users`, { params });
    return data?.data?.[0];
  } catch (e) {
    console.error(e);
  }
};

const useUser = ({ id }) => {
  const authAxios = useAxios();
  return useQuery(['user', id], () => fetchUser(authAxios, { id }), {
    refetchOnWindowFocus: false,
  });
};

export default useUser;
