import React from 'react';
import { Routes, Route } from 'react-router-dom';

import RecipesList from './list';
import ViewRecipe from './view';
import NewRecipe from './new';

const Recipes = () => {
  return (
    <>
      <Routes>
        <Route index element={<RecipesList />} />
        <Route path=":id" element={<ViewRecipe />} />
        <Route path="new" element={<NewRecipe />} />
      </Routes>
    </>
  );
};

export default Recipes;
