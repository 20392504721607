import { ErrorMessage } from '@hookform/error-message';

const ImageInput = ({ register, label, fileList, errors, name }) => {
  return (
    <div>
      <label
        htmlFor="cover_photo"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center flex flex-col items-center space-y-4">
          {!!fileList ? (
            <div className="">
              <img
                className="m-auto h-32 w-auto rounded-lg"
                src={URL.createObjectURL(fileList[0])}
              />
              <p className="text-sm font-semibold text-gray-500">
                {fileList[0]?.name}
              </p>
            </div>
          ) : (
            <>
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          )}
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer py-0.5 px-2 bg-white rounded-md font-semibold text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>Select Image</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                accept=".jpeg,.jpg,.png"
                {...register}
              />
            </label>
          </div>
          <p className="text-xs text-gray-500">
            PNG, JPG, up to 1MB
            <br />
            <br />
            Please use{' '}
            <a href="https://tinypng.com" target="_blank" className="text-bold">
              Tiny PNG
            </a>{' '}
            to compress the images first.
          </p>
          {errors && (
            <div className="h-2">
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <p className="text-red-500 text-xs">{message}</p>
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageInput;
