import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchRecipe = async (authAxios, { id }) => {
  const params = {
    _id: id,
  };
  try {
    const { data } = await authAxios.get(`/mealplans`, { params });
    return data?.data?.[0];
  } catch (e) {
    console.error(e);
  }
};

const useRecipe = ({ id }) => {
  const authAxios = useAxios();
  return useQuery(['mealplan', id], () => fetchRecipe(authAxios, { id }));
};

export default useRecipe;
