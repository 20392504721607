import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormLayout from '../../../components/Form/FormLayout';
import FormSection from '../../../components/Form/FormSection';
import Input from '../../../components/Form/Input';
import DropDown from '../../../components/DropDown';
import { useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../../../contexts/axios';
import { toast } from 'react-toastify';

const NewSnack = () => {
  const { handleSubmit, register, control } = useForm();
  const authAxios = useAxios();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    await createSnackItem.mutateAsync({
      ...data,
      nutrition: {
        calories: data['calories'],
        protein: data['protein'],
        carbohydrates: data['carbohydrates'],
        fat: data['fat'],
        fiber: data['fiber'],
      },
    });
  };

  const createSnackItem = useMutation(
    (payload) => authAxios.post(`/snacks`, payload),
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(['snacks']);
        toast.success('Snack Saved');
        navigate(`/snacks`, { replace: true });
      },
      onError: (error) => {
        console.log(error);
        toast.error(error);
      },
    }
  );

  return (
    <div>
      <FormLayout title={'Create New Snack'} onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-6">
          <Input
            label="Name"
            placeholder="Whole Wheat Bread"
            id="name"
            name="name"
            type="text"
            register={register('name', {
              required: 'This field is required.',
            })}
          />
          <div className="flex-1">
            <Input
              label="Description"
              placeholder="2 slices (toasted in 1 tsp of butter or ghee)"
              id="description"
              name="description"
              type="text"
              register={register('description', {
                required: 'This field is required.',
              })}
            />
          </div>
        </div>
        <div>
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <DropDown
                onChange={onChange}
                value={value}
                label={'Category'}
                defaultText={'Select a Category'}
                options={[
                  'Wake Up',
                  'Pre Breakfast',
                  'Breakfast',
                  'Post Breakfast',
                  'Mid Morning Snack',
                  'Pre Lunch',
                  'Lunch',
                  'Post Lunch',
                  'Mid Afternoon Snack',
                  'Early Evening Snack',
                  'Evening Snack',
                  'Late Evening Snack',
                  'Pre Workout',
                  'Post Workout',
                  'Dinner',
                  'Post Dinner',
                  'Bed Time',
                ]}
              ></DropDown>
            )}
          />
        </div>
        <FormSection>
          <Input
            label="Calories"
            id="calories"
            name="calories"
            type="number"
            step=".01"
            noPadd={true}
            register={register('calories', {
              required: 'This field is required.',
            })}
          />
          <Input
            label="Protein"
            id="protein"
            name="protein"
            type="number"
            step=".01"
            noPadd={true}
            register={register('protein', {
              required: 'This field is required.',
            })}
          />
          <Input
            label="Carbohydrates"
            id="carbohydrates"
            name="carbohydrates"
            type="number"
            step=".01"
            noPadd={true}
            register={register('carbohydrates', {
              required: 'This field is required.',
            })}
          />
          <Input
            label="Fat"
            id="fat"
            name="fat"
            type="number"
            step=".01"
            noPadd={true}
            register={register('fat', {
              required: 'This field is required.',
            })}
          />
          <Input
            label="Fiber"
            id="fiber"
            name="fiber"
            type="number"
            step=".01"
            noPadd={true}
            register={register('fiber', {
              required: 'This field is required.',
            })}
          />
        </FormSection>
        <button
          type="submit"
          className="disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create
        </button>
      </FormLayout>
    </div>
  );
};

export default NewSnack;
