import React from 'react';
import { Routes, Route } from 'react-router-dom';

import IngredientsList from './list';

const Ingredients = () => {
  return (
    <>
      <Routes>
        <Route index element={<IngredientsList />} />
        <Route path=":id" element={<h1>View Ingredient (WIP)</h1>} />
      </Routes>
    </>
  );
};

export default Ingredients;
