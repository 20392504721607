import React from 'react';
import Information from './Information';

const NutritionCard = ({ nutrition }) => {
  return (
    <div className="flex w-full space-x-8">
      <Information title={'Calories (kcal)'} value={nutrition?.calories} />
      <Information title={'Protein (g)'} value={nutrition?.protein} />
      <Information
        title={'Carbohydrates (g)'}
        value={nutrition?.carbohydrates}
      />
      <Information title={'Fats (g)'} value={nutrition?.fat} />
      <Information title={'Fiber (g)'} value={nutrition?.fiber} />
    </div>
  );
};

export default NutritionCard;
