import { matchRoutes, useLocation } from 'react-router-dom';

const routes = [
  { path: '/' },
  { path: '/users' },
  { path: '/users/:id' },
  { path: '/mealplans/upcoming' },
  { path: '/mealplans/active' },
  { path: '/mealplans/:id' },
  { path: '/mealplans/:id/change' },
  { path: '/mealplans/:id/add-snack' },
  { path: '/recipes' },
  { path: '/recipes/:id' },
  { path: '/recipes/new' },
  { path: '/ingredients' },
  { path: '/ingredients/:id' },
  { path: '/snacks' },
  { path: '/snacks/new' },
  { path: '/snacks/:id' },
  { path: '/analytics' },
  { path: '/analytics/subscriptions' },
  { path: '/analytics/events' },
  { path: '/analytics/users' },
  { path: '/ingredients/*' },
  { path: '/settings' },
  { path: '/schedule' },
];

const useCurrentPath = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location);
  return route.path;
};

export default useCurrentPath;
